
































































































































































































.menuLabel {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.loginContainer {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // background: -webkit-linear-gradient(left, #2a3c59, #425c7b 50%, #2a3c59 100%);
  background: url("../../assets/bgc.png") 0 0 no-repeat;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 14.5rem;
    background-size: cover;
    background-position: center;
  }
}
.loginControl {
  width: 28rem;
  margin-top: -1rem;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
  }
}
.loginLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: initial;
  margin-bottom: 1rem;
  white-space: nowrap;
}
.loginForm {
  width: 28rem;
  padding: 1.25rem;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.loginContent {
  background-color: #fff;
  padding: 0 50px;
  position: relative;
  z-index: 1;
  border-radius: 0.25rem;
}
.loginTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  display: block;
  padding: 40px 0;
  font-family: PingFang SC;
}
.loginOther {
  padding: 0 0.25rem;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  span {
    color: #f0f5fb;
    cursor: pointer;
  }
}
.loginFoot {
  position: absolute;
  color: #fff;
  bottom: 20px;
  span {
    font-size: 14px;
    font-family: PingFang SC;
  }
  .loginpadding {
    padding: 20px 0;
    span {
      color: #fff;
      padding-left: 5px;
    }

    .el-link.el-link--default:hover {
      color: #fff;
    }
  }
}
.login-title {
  position: absolute;
  top: 0;
  color: #fff;
  /* line-height: 9vh; */
  height: 10vh;
  line-height: 10vh;
  font-size: 30px;
  margin: 0 auto;
  letter-spacing: 5px;
  /* position: relative; */
  padding-left: 90px;
  font-family: PingFang SC;
  span {
    &::before {
      content: "";
      display: inline-block;
      width: 75px;
      height: 50px;
      background: red;
      position: absolute;
      left: 0;
      top: 17px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAiCAYAAADvVd+PAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABDtJREFUeNrMmn9ojVEYx/ded7NrLGUYMaGxmRkRkY20/GqRIUx+i2lEyR8k/DH/kIWILL8i5q/5Y35kxWqptRWy5g8/EmFbfm62Gda9vg/PrevuPec99z3vy33q07rvPe95n/O9zznPOc87IxAIxLhoHjAZ7AYZYADoyde7QAtoBM/AY3AXPAHfwPeYf2CGpgCxYCCgTpp5UN2eAXLAYZAJ4i36/ATKwTnQwCK5ZySABstAe+CPzQeGpK0X7AJvAup2C8wEPk0/heh2cDnE2SOgp8I9w8EV8D4CIYrBoGgUYCGoAQ/BDIsICCcf3Ac/FUW4DYY4LYDhwCI4mBesjzbu7Q12ghVgtEL7m9y2NVrWAKeYCC6CFoVIOA1So2UKREKiQptVoEpBhGowL1qmgMoUoTBP4Xx/FdRL2g8Ba8EmMFTS7jM4AE4A/7+YArEcerER3JMATob9erRgbldIbTngvEUkdIFD/BxXp8BycAPU8d8C4FG4L1mQ98nxCoUw7gNWglqJCH4WOdEtAciBD2EPpc95igI0Spx/w85bpTeKvH2gQxIJx0Cc0wLkgmbBQ2kz009BgLcKi1o9KLLoi3aS00G5oI9OsMdJAdLBU4nT9F2aQwKQtYJKMMWizySwWhBZX3m6aguQxL+w1QI0xUEBgtYESnj+i/o1eJE067uB9xVaAqxR3KIucUEAsh/gOZhqsb0uFNx/RjUzeEwyYyrYD7wKWXQMn++dNjpmjwSnQB9Juzrw1eT6OpCnWrAINRpMARiu6GgG7+fdMqofxEm+7wAfTK57eTM1NFIB6IaiCBwca/EL6VqVRWWoNxdkzGwuyOaCjJIAFHaLQP8IHExzSYB2UA22CEI8WGnKBb0k/SyXCNRNgL5gtY2a32CHB/8C7OUy2lPJGpEP9lj0NYenqaUABp/Hx9pweBw7pGtN4DyYBY5K2o0Au8AVhfWH1o/pMv88IQ0XaCxUCRoD7+K5vhmsB68F7WiqLeGBH7RYHMOjQBil3hABZtscAEWAD3wRzNMeknup6nsJHOdSuMimcWrbaMO/CbwOvJIJkMgDsWOjWAAz8wtWcSppXQOnwQOL2gAtZNu4nmDH4nna1IqmgMGbH8PmA3ySEKOa/oWwa3fABi54yAa/FJzl9wkpmutLhmjKeFmETM0HZIEakxcjnaCEC6a0x3gJroN3kr7Gc/pb4WCKzTLZ8/wlwDAHHkCh1iaIguMKfVA+3wpW2cxGMsvmKVcBSs1KYmcCelbDJ0id9wuVEbwjsGt0XC4LPWnGcKWlQbPjbzbf3KSAUouqkRtWFizpkRNtXFfTtfQIB7+Dj7z/w2i8i4PHYZ9GBgi1SRY5PzSn3wPFfOT9H2ZwJvr9XsDvkAC0qZkpOJ6SJXMdv8DlE6Sq0SErkbJAoShF2DgY9eOUFzDZjKSDR/yPEP4oEOC3j78EGADfwSB2Dlmk1AAAAABJRU5ErkJggg==)
      no-repeat 50%;
    }
  }
}

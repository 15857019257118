





































































































































































































































































































































.loginContainer {
  .loginInput {
    margin-top: 1rem;
  }
  .inputbox {
    display: flex;
    overflow: hidden;
    align-items: center;
  }
  .login_input {
    display: flex;
    flex-direction: column;
    flex: 1;
    .el-form-item {
      border-bottom: 1px solid #ccc;
      display: flex;
      // overflow: hidden;
      align-items: center;
      .loginput input {
        border: none;
      }
    }
    .el-form-item__content {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }
  .loginButton {
    padding: 1.25rem 0 50px;
    text-decoration: none;
    > .el-button {
      width: 100%;
      height: 2.5rem;
    }
  }
  .code .el-input__inner {
    border: 0;
  }
}
.loginpadding {
  .el-link--inner {
    display: flex;
    align-items: center;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}
.el-input__inner {
  width: 300px;
}
